@import '../../../CSS/variables.module';

.email-code-confirmation {
    /*
    color: #fff;
    */
    margin: auto;
    text-align: center;
    font-weight: 600;
    padding: 60px 0 32px;
    /*
    min-height: calc(100vh - 265px);
    */
    display: flex;
    flex-flow: column;
    align-items: center;

    .confirmation-container {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        max-width: 300px;
        padding-bottom: 80px;

        .confirmation-container-text {
            display: block;
        }

        .email-container {
            padding-top: 24px;
            display: flex;

            .content-container {
                border: 1px solid $grey;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                padding: 14px 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                overflow: hidden;

                span {
                    padding-left: 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .spacer {
                flex-grow: 1;
            }
        }
    }

    .code-container {
        width: 100%;

        .enter-code-label {
            margin: 0 0 24px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            text-align: center;

            /* MO/Text/Mono 600 */

            color: #2b2b2b;
            span {
                display: block;
            }
        }

        .expiration-timer {
            margin-top: 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }
    }

    .resend-container {
        /*
        margin-top: auto;
        */
        padding-top: 40px;

        .resend-text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
        }

        .resend-button-container {
            margin-top: 16px;
            font-size: 14px;
            line-height: 17px;

            button {
                width: 100%;
                /*
                border: 1px solid #ffffff;
                */
                box-sizing: border-box;
                border-radius: 6px;
                background-color: transparent;
                align-items: center;
                text-align: center;
                letter-spacing: 0.0125em;
                /*
                color: #fff;
                */
                text-transform: uppercase;
                padding: 11px;
                cursor: pointer;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.0125em;
                font-family: $fontFamily, sans-serif;
                max-width: 360px;
            }

            div {
                display: flex;
                flex-direction: row;
                justify-content: center;

                div {
                    margin-right: 10px;
                }
            }
        }

        &.expired {
            margin-top: 0;
            padding-top: 20px;
            width: 100%;

            .resend-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
            }

            .resend-button-container {
                margin-top: 13px;

                button {
                    background: linear-gradient(156.39deg, #007DBB 48.1%, #58B0E5 105%);
                    border-radius: 8px 8px 8px 0;
                    padding: 12px;
                    border: none;
                    font-family: $fontFamily, sans-serif;
                }
            }
        }
    }

    .code-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 328px;
        margin: auto;

        input {
            text-align: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
            color: $dark;
            width: 52px;
            height: 74px;
            background: #ffffff;
            border: 1px solid #cacbd0;
            border-radius: 4px;
        }

        :focus {
            outline: none !important;
        }
    }

    .error-container {
        display: flex;
        justify-content: center;
        /*
        background: #ffffff;
        */
        border: 1px solid $red;
        border-radius: 4px;
        padding: 8px 10px;
        color: $red;
        position: relative;
        max-width: 328px;
        margin: 7px auto 0;

        p {
            margin: 0;
            color: $dark;
            font-weight: 500;
            font-size: 14px;
            text-align: start;
            padding-left: 10px;

            span {
                display: block;
            }
        }

        .triangle {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent $red transparent;
        }
    }

    &.has-error {
        .code-input input {
            border: 1px solid $red;
        }
    }

    .user-created-confirmation-container {
        display: flex;
        align-items: center;
        flex-flow: column;
        flex: 1;
        justify-content: space-evenly;

        svg {
            max-width: 100%;
        }

        .check-icon {
            font-size: 80px;
            color: $primary;
        }
    }

    @media (max-width: 767.9px) {
        padding: 24px 0 16px;
        min-height: calc(100vh - 295px);
        .confirmation-container {
            padding-bottom: 24px;

            .email-container {
                padding-top: 16px;
            }
        }
        .resend-container {
            width: 100%;
        }
    }

    .error {
        color: $red;
    }
}
.active-color {
    color: $primary !important;
}
.email-change-validation-head {
    /*    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;*/
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;

        padding: 0;
        margin: 0;
        text-align: center;

        span {
            font-weight: 700;
            max-width: 250px;
        }
    }
    margin-bottom: 25px;
}
.email-code-confirmation.verify {
    padding-top: 0;
}
