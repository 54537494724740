@import 'variables.module';
@import './hpColors';

.homepage-container {
    max-width: 1224px;
    margin: 0 auto;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
}
.homepage .top-section {
    position: relative;
    color: $white;
}
.homepage .top-section .background-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.homepage .top-section .background-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.homepage .top-section .background-container .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
            155.35deg,
            rgba(2, 127, 187, 0.102) 0%,
            rgba(0, 128, 201, 0.288) 62.97%,
            rgba(0, 115, 166, 0.216) 90.52%,
            rgba(0, 125, 187, 0.306) 107.95%
        ),
        linear-gradient(
            179.9deg,
            rgba(95, 95, 95, 0.427) -9.84%,
            rgba(38, 38, 38, 0.588) 4.72%,
            rgba(56, 56, 56, 0.7) 37.94%,
            rgba(42, 45, 49, 0.7) 69.58%,
            rgba(48, 50, 52, 0.441) 85.56%
        );
}
.homepage .top-section .homepage-container {
    padding-top: 170px;
    padding-bottom: 50px;
    min-height: calc(100vh * 0.6);
    @media screen and (max-width: 1024px) {
        min-height: calc(calc(var(--vh, 1vh) * 100) * 0.6);
    }
}
.homepage .top-section .event-details-container {
    display: flex;
    justify-content: space-between;
}
.homepage .top-section .event-details-container .main-details {
    flex: 1;
    padding-inline-end: 10%;
}
.homepage .top-section .event-details-container .main-details .details-information {
    max-width: 700px;
}
.homepage .top-section .event-details-container .main-details p {
    margin: 20px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #f5f5f5;
}
.homepage .top-section .event-details-container .main-details .organizer-name {
    word-break: break-word;
    color: $white;
}
.homepage .top-section h1 {
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: 90px;
    line-height: 90%;
    overflow-wrap: break-word;
}
.homepage .top-section .extra-details {
    border-radius: 8px;
    width: 395px;
    border: 1px solid $disabled-text;
    padding: 21.53px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
}

.homepage .top-section .extra-details p {
    margin: 0;
    word-break: break-word;
}
.homepage .top-section .single-detail-container {
    margin-bottom: 20px;
}
.homepage .top-section .single-detail-container:last-child {
    margin-bottom: 0;
}
.homepage .top-section .extra-details p.label {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    color: $white;
}
@media screen and (max-width: 1080px) {
    .homepage .top-section .extra-details {
        max-width: 395px !important;
    }
}
@media screen and (max-width: 769px) {
    // .homepage .partners-section .bottom-slider-container .partner-details-container .main-details p {
    //     font-size: 16px;
    // }
    .homepage .top-section .event-details-container .main-details p {
        font-size: 16px;
    }
    .homepage .top-section .extra-details p.label {
        font-size: 20px;
        margin-bottom: 4px;
        line-height: 30px;
    }
    .homepage .top-section .single-detail-container {
        margin-bottom: 16px;
    }
}

.homepage .top-section .extra-details p.information {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    a {
        color: $white;
        text-decoration: underline;
    }
}
.homepage .program-section {
    margin-bottom: 100px;
}
.homepage .program-section .tabs-header {
    background: var(--secondary-color);
    box-shadow: 0 -1px 4px rgba(31, 37, 86, 0.25);
    margin-bottom: 50px;
    min-height: 41px;
    height: 41px;
    max-height: 41px;
}
.homepage .program-section .tabs-header.secondary-colors {
    background: var(--primary-color);
}
.homepage .program-section .tabs-name-container {
    display: flex;
}
.homepage .program-section .tabs-content .event-description-container {
    display: flex;
    justify-content: space-between;
}
.homepage .program-section .tabs-content .event-description-container .description {
    font-size: 18px;
    flex: 1;
    padding-inline-end: 10%;
    span ul,
    span ol {
        font-size: 18px;
    }
}
.homepage .program-section .tabs-content .event-description-container .description p {
    max-width: 700px;
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    color: $dark;
    overflow-wrap: break-word;
}
.homepage .program-section .tabs-content .event-description-container .video-container {
    width: 290px;
    height: 200px;
    position: relative;
    margin-bottom: 40px;
    cursor: pointer;
}
.homepage .program-section .tabs-content .event-description-container .video-container img.background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid $white;
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 1px;
}

.homepage .program-section .tabs-content .event-description-container .video-container .video-overlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
}
.homepage .program-section .tabs-content .event-description-container .video-container .video-overlay svg {
    width: 30px;
    height: auto;
}
.homepage .program-section .tabs-content .event-description-container .random-banner {
    width: 290px;
    height: 200px;
    position: relative;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.homepage .program-section .tabs-content .event-description-container .random-banner a {
    display: block;
    width: 290px;
    height: 200px;
    font-size: 0;
}
.homepage .program-section .tabs-content .event-description-container .random-banner img.background {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid $white;
    box-sizing: border-box;
    padding: 1px;
}

.homepage .program-section .tabs-content .single-auditorium-program {
    margin-bottom: 50px;
}
.homepage .program-section .tabs-content .single-auditorium-program:last-child {
    margin-bottom: 0;
}
.homepage .program-section .tabs-content .single-auditorium-program .timezone-text {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-top: 4px;
    display: block;
}
.homepage .program-section .tabs-content .auditorium-name {
    font-weight: bold;
    font-size: 42px;
    line-height: 42px;
    color: var(--primary-color);
    margin-top: 0;
    margin-bottom: 0;
}
.homepage .program-section .tabs-content .auditorium-name .timezone {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
}
.homepage .program-section .tabs-content .auditorium-program-wrapper {
    padding-top: 16px;
}
.homepage .program-section .tabs-content .single-day-program-wrapper {
    display: flex;
    margin-bottom: 100px;
}
.homepage .program-section .tabs-content {
    .program-date {
        font-weight: bold;
        margin: 0;
        color: var(--secondary-color);
        .current-date {
            display: flex;
            flex-flow: column;
            .first {
                font-size: 42px;
                line-height: 42px;
            }
            .second {
                display: flex;
                flex-flow: column;
                font-size: 16px;
                line-height: 21px;
                text-transform: capitalize;
                font-weight: 600;
            }
        }
        .buildings-list {
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-top: 40px;
            .name {
                margin: 0;
                padding: 16px 0;
                color: $dark;
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;
                cursor: pointer;
                &.active {
                    background: $secondary;
                    box-shadow: 0px -1px 4px rgba(31, 37, 86, 0.25);
                    color: $white;
                    border-radius: 8px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.homepage .program-section .tabs-content .auditoriums-single-day-program-wrapper {
    flex: 1;
    overflow: hidden;
}
.homepage .program-section .tabs-content .program-list-container {
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.homepage .program-section .tabs-content .program-entry {
    break-inside: avoid;
    position: relative;
    width: 50%;
    &:nth-child(odd) {
        margin-inline-end: 24px;
        width: calc(50% - 24px);
    }
}
.homepage .program-section .tabs-content .program-entry .program-time {
    color: $dark;
    font-size: 24px;
    line-height: 36px;
}
.homepage .program-section .tabs-content .program-entry:last-child {
    padding-bottom: 0;
}
.homepage .partners-section {
    margin-bottom: 100px;
}
.homepage .partners-section .tabs-header {
    background: var(--secondary-color);
    box-shadow: 0 -1px 4px rgba(31, 37, 86, 0.25);
    margin-bottom: 40px;
}
.homepage .partners-section .tabs-header.secondary-colors {
    background: var(--primary-color);
}
.homepage .partners-section .tabs-name-container {
    display: flex;
}
.homepage .program-section .tabs-content .go-to-tab-button {
    max-width: 194px;
    background: var(--primary-color);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    margin-top: 30px;
    cursor: pointer;
    transition: all 0.12s ease-in-out;
    outline: none;
    border: 1px solid transparent;
}
.homepage .program-section .tabs-content .go-to-tab-button:hover {
    box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
    transition: all 0.12s ease-in-out;
}
.homepage .program-section .tabs-content .go-to-tab-button:focus {
    background-color: $white;
    transition: all 0.12s ease-in-out;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}
.homepage .program-section .tabs-content .go-to-tab-button.secondary-color-btn {
    background: var(--primary-color);
    color: $white;
}
.homepage .program-section .tabs-content .go-to-tab-button.secondary-color-btn:focus {
    background-color: $white;
    transition: all 0.12s ease-in-out;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}
.homepage .partners-section .top-slider {
    position: relative;
}

.single-slide-container {
    width: 180px !important;
}

.homepage .partners-section .top-slider-container .slider-image-container {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 16px;
    width: auto;
    height: auto;
    max-width: initial;
    margin: 0 12px;
    position: relative;
}

.homepage .partners-section .top-slider-container .slider-image-container img,
.homepage .partners-section .top-slider-container .slider-image-container p {
    width: 100%;
    height: 116px;
    margin: 0;
}
.homepage .partners-section .top-slider-container .slider-image-container p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.homepage .partners-section .top-slider {
    overflow: hidden;
}
.homepage .partners-section .top-slider .arrow {
    background: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}
.homepage .partners-section .top-slider .arrow.previous {
    left: -75px;
}
.homepage .partners-section .top-slider .arrow.next {
    right: -75px;
}
.homepage .partners-section .top-slider .arrow.previous svg {
    margin-left: 60px;
}
.homepage .partners-section .top-slider .arrow.next svg {
    margin-right: 60px;
    transform: rotate(180deg);
}

.homepage .partners-section .top-slider .slick-current .slider-image-container:after {
    content: '';
    height: 8px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0 0 8px 8px;
    background-color: var(--secondary-color);
    position: absolute;
}
.homepage .partners-section .partners .top-slider .slick-current .slider-image-container:after {
    background-color: var(--primary-color);
}
.homepage .partners-section .bottom-slider-container {
    margin-top: 40px;
    padding: 0 30px;
}
.homepage .partners-section .bottom-slider-container .slick-list {
    transition: height 0.3s ease-in-out;
}
.homepage .partners-section .bottom-slider-container .homepage-container {
    position: relative;
    padding: 0;
    .partner-details {
        position: absolute;
        top: 0;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s;
        width: 100%;
        &.active {
            position: initial;
            z-index: 0;
            opacity: 1;
            transition: opacity 0.2s;
        }
    }
}
.homepage .partners-section .bottom-slider-container .partner-details-container {
    display: flex;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .main-details {
    flex: 1;
    padding-inline-end: 100px;
    display: flex;
    align-items: center;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .main-details .info {
    max-width: 700px;
    width: 100%;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .main-details p {
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: $dark;
    margin-bottom: 58px;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .main-details .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .main-details h2 {
    margin: 0;
    font-size: 34px;
    line-height: 44px;
    color: $dark;
    display: none;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .main-details .contact h2 {
    display: block;
    font-weight: normal;
    max-width: 512px;
    cursor: pointer;
}

.homepage .partners-section .bottom-slider-container .partner-details-container .logo {
    position: static;
    width: 290px;
    display: flex;
    align-items: center;
    padding: 0 75px;
    border-inline-start: 2px solid var(--secondary-color);
}
.homepage .partners-section .partners .bottom-slider-container .partner-details-container .logo {
    border-inline-start: 2px solid var(--primary-color);
}
.homepage .partners-section .bottom-slider-container .partner-details-container .logo .partner-logo-container {
    width: auto;
    height: auto;
}
.homepage .partners-section .bottom-slider-container .partner-details-container .logo .partner-logo-container img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.homepage
    .partners-section
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.homepage
    .partners-section
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container
    .webpage-link {
    font-size: 24px;
    line-height: 36px;
    text-align: right;
    padding-inline-start: 50px;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.homepage
    .partners-section
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container
    a {
    margin-inline-start: 20px;
    color: $dark;
}
.homepage
    .partners-section
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container
    a:first-child {
    margin-inline-start: 0;
}

.homepage .previous-events-section {
    background-color: #f5f5f5;
    padding: 50px 0 100px;
}
.homepage .previous-events-section h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    margin: 0;
    color: $dark;
}
.homepage .previous-events-section .links-container {
    display: flex;
    flex-flow: column;
    margin-top: 40px;
}
.homepage .previous-events-section .links-container .link-container {
    margin-bottom: 10px;
}
.homepage .previous-events-section .links-container a {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: var(--secondary-color);
}

.homepage .previous-events-section .links-container .link-container:last-child {
    margin-bottom: 0;
}
.homepage .program-section .tabs-content .building-name {
    display: none;
}
.partners-section {
    &.sponsors-mobile {
        display: none;
        @media screen and (max-width: 480px) {
            display: block;
        }
    }
}
@media screen and (min-width: 1200px) {
    .outside-event-header .menu-wrapper .menu-links {
        display: flex;
        justify-content: flex-end;
    }
}
@media all and (max-width: 1199.9px) {
    .outside-event-header {
        .menu-wrapper {
            .menu-links {
                display: none;
            }
            .sm-share-button {
                display: none;
            }
        }
    }
    .homepage-container {
        max-width: 992px;
    }
    .homepage {
        .top-section {
            h1 {
                font-size: 70px;
            }
            .event-details-container {
                flex-flow: column;
                .main-details {
                    padding-inline-end: 0;
                    p {
                        margin-bottom: 0;
                    }
                }
                .extra-details-wrapper {
                    padding-top: 30px;
                }
            }
        }
    }
    .homepage .program-section .tabs-content .event-description-container {
        flex-flow: column;
    }
    .homepage .program-section .tabs-content .event-description-container .event-description-container-title {
        font-weight: bold;
        font-size: 42px;
        line-height: 42px;
        color: var(--secondary-color);
        margin-top: 0;
        margin-bottom: 20px;
    }
    .homepage .program-section .tabs-content .event-description-container .description {
        padding-inline-end: 0;
    }
    .homepage .program-section .tabs-content .event-description-container .media-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 100px;
    }
    .homepage .program-section .tabs-content .event-description-container .video-container {
        margin-bottom: 0;
    }
    .homepage .program-section .tabs-content .event-description-container .video-container,
    .homepage .program-section .tabs-content .event-description-container .random-banner {
        width: 270px;
        height: 190px;
        a {
            height: 190px;
        }
    }
    .homepage .program-section .tabs-content .program-wrapper .event-description-container-title {
        font-weight: bold;
        font-size: 42px;
        line-height: 42px;
        color: $dark;
        margin-top: 0;
        margin-bottom: 24px;
    }
    .homepage .program-section .tabs-content .auditorium-name {
        font-size: 34px;
        line-height: 34px;
    }
    .homepage .program-section .tabs-content .program-list-container {
        flex-direction: column;
        .program-entry {
            width: 100%;
            margin-inline-end: 0;
        }
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container {
        word-break: break-word;
        flex-flow: column;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details {
        padding-inline-end: 0;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details .contact > a {
        display: none;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details h2 {
        display: inline-block;
        margin-bottom: 20px;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details .contact h2 {
        margin-bottom: 0;
    }
    .homepage
        .partners-section
        .bottom-slider-container
        .partner-details-container
        .main-details
        .contact
        .links-container {
        justify-content: flex-start;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .logo {
        border-left: none;
        padding-inline-start: 0;
        align-self: center;
        display: none;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details p {
        margin-bottom: 20px;
    }
    .homepage
        .partners-section
        .bottom-slider-container
        .partner-details-container
        .main-details
        .contact
        .links-container
        .webpage-link {
        padding-inline-start: 40px;
    }
    .homepage
        .partners-section
        .bottom-slider-container
        .partner-details-container
        .main-details
        .contact
        .links-container
        .webpage-link.no-social-links {
        margin-inline-start: 0;
        max-width: 600px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1199.9px) {
    .outside-event-header {
        &.hide-bottom-menu {
            .menu-wrapper {
                .menu-links {
                    display: flex;
                }
            }
            .mobile-menu-wrapper {
                display: none;
            }
        }
    }
    .menu-links {
        min-width: 208px;
    }
}
@media screen and (min-width: 768px) and (max-width: 780px) {
    .menu-wrapper .select-language-container {
        margin-inline-start: 15px;
    }
    .outside-event-header .menu-links a {
        margin-inline-start: 15px;
    }
}

@media all and (max-width: 767.9px) {
    .menu-links {
        min-width: 175px;
    }
    .homepage .top-section .event-details-container .main-details {
        padding-left: 30px;
        padding-right: 30px;
    }
    #cookie-banner {
        top: 0;
        bottom: initial !important;
    }
    .outside-event-header .menu-wrapper {
        justify-content: center;
        padding-top: 30px;
        padding-inline-start: 50px;
        padding-inline-end: 50px;
    }
    .homepage .top-section .homepage-container {
        padding-top: 100px;
        padding-bottom: 80px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .homepage .top-section h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 42px !important;
        line-height: 90%;
        letter-spacing: -0.025em;
    }
    .homepage .top-section .event-details-container .main-details .organizer-name {
        display: block;
        margin-top: 10px;
        overflow-wrap: break-word;
    }
    .homepage .top-section .extra-details {
        width: auto;
        max-width: 100%;
        padding-left: 28px;
        padding-right: 28px;
    }
    .homepage .program-section .tabs-content .event-description-container .media-container {
        flex-flow: column;
    }
    .homepage .program-section .tabs-content .event-description-container .video-container {
        margin-bottom: 40px;
    }
    .homepage .partners-section .top-slider .arrow {
        display: none;
    }
    .homepage .partners-section .top-slider {
        padding: 0 18px;
    }
    .homepage .booth-video-container {
        width: auto;
        height: 220px;
    }
    .homepage .booth-dialog-content #form-dialog-title {
        min-width: auto;
    }
    .homepage .program-section .tabs-content .single-day-program-wrapper {
        flex-flow: column;
    }
    .homepage .program-section .tabs-content .program-date {
        width: auto;
    }
    .homepage .program-section .tabs-content .program-date .current-date {
        flex-flow: row;
        width: 100%;
        margin-bottom: 6px;
        align-items: center;
        .second {
            padding-inline-start: 8px;
        }
    }
    .homepage .program-section .tabs-content .program-date .buildings-list {
        display: none;
    }
    .homepage .program-section .tabs-content .building-name {
        background: $secondary;
        box-shadow: 0px -1px 4px rgba(31, 37, 86, 0.25);
        color: $white;
        border-radius: 8px;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 8px 16px;
        min-height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 232px;
        align-self: flex-end;
        font-size: 16px;
        line-height: 20px;
    }
    .homepage .program-section .tabs-content .auditoriums-single-day-program-wrapper .single-building-program {
        display: flex !important;
        flex-flow: column;
        margin-bottom: 30px;
    }
    .homepage .program-section .tabs-content .program-date {
        flex-flow: row;
        flex: 0 0 auto;
    }
}
.homepage
    .partners-section
    .partners
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container
    a {
    &:hover {
        svg path {
            fill: var(--primary-color);
        }
    }
}
.homepage
    .partners-section
    .partners
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container
    a {
    &:hover {
        svg path {
            fill: var(--primary-color);
        }
    }
}
.homepage
    .partners-section
    .bottom-slider-container
    .partner-details-container
    .main-details
    .contact
    .links-container
    a {
    &:hover {
        svg path {
            fill: var(--secondary-color);
        }
    }
}
@media (hover: hover) {
    .homepage .program-section .tabs-content .event-description-container .video-container:hover img.background {
        border: 2px solid var(--secondary-color);
        padding: 0;
    }
    .homepage .program-section .tabs-content .event-description-container .video-container:hover svg path {
        stroke: var(--secondary-color);
    }
    .homepage .program-section .tabs-content .event-description-container .random-banner:hover img.background {
        border: 2px solid var(--secondary-color);
        padding: 0;
    }
    .homepage .partners-section .top-slider-container .slider-image-container:hover {
        padding: 14px;
        border: 2px solid var(--secondary-color);
        box-sizing: border-box;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
    .homepage .partners-section .top-slider-container .slick-current .slider-image-container:hover {
        border-bottom: none;
        padding-bottom: 16px;
    }
    .homepage .partners-section .partners .top-slider-container .slider-image-container:hover {
        border-color: var(--primary-color);
    }
    .homepage .partners-section .top-slider .arrow:hover {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    }
    .homepage .partners-section .top-slider .arrow:hover svg path {
        stroke: var(--secondary-color);
    }
    .homepage .partners-section .partners .top-slider .arrow:hover svg path {
        stroke: var(--primary-color);
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details h2:hover {
        color: var(--secondary-color);
        text-decoration: underline;
        padding-bottom: 0;
    }
    .homepage .partners-section .bottom-slider-container .partner-details-container .main-details h2.no-hover:hover {
        color: $dark;
        text-decoration: none;
        padding-bottom: 0;
        cursor: initial;
    }
    .homepage .partners-section .partners .bottom-slider-container .partner-details-container .main-details h2:hover {
        color: var(--primary-color);
        text-decoration: underline;
    }
    .homepage
        .partners-section
        .exhibitors
        .bottom-slider-container
        .partner-details-container
        .main-details
        h2.no-hover:hover {
        color: $dark;
        text-decoration: none;
    }
    .homepage
        .partners-section
        .bottom-slider-container
        .partner-details-container
        .main-details
        .contact
        .links-container
        a:hover {
        color: var(--secondary-color);
    }
    .homepage
        .partners-section
        .partners
        .bottom-slider-container
        .partner-details-container
        .main-details
        .contact
        .links-container
        a:hover {
        color: var(--primary-color);
    }
    .homepage .previous-events-section .links-container a:hover {
        color: var(--primary-color);
    }
}

.homepage-speakers-container {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: $dark;
    .homepage-speakers-title {
        font-weight: 700;
    }
    .homepage-speakers-names {
        font-weight: 400;
    }
}
@media screen and (min-width: 600px) and (max-width: 767.9px) {
    .outside-event-header {
        &.hide-bottom-menu:not(.white) {
            .menu-wrapper {
                position: relative;
                .brand-logo {
                    justify-content: center;
                }
                .select-language-container {
                    position: absolute;
                    right: 15px;
                }
            }
        }
    }
}
@media screen and (max-width: 860px) and (min-width: 449px) {
    .outside-event-header .menu-links a {
        width: -webkit-fill-available !important;
    }
}
@media screen and (max-width: 479px) {
    .homepage {
        .program-section {
            .tabs-content {
                .event-description-container {
                    .event-description-container-title {
                        word-break: break-word;
                    }
                }
            }
        }
    }
}
