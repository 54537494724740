@import 'variables.module';
@import './hpColors';

.sticky-menu .menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1300;
}
.fullscreen-video .menu {
    height: 0;
    overflow: hidden;
    display: none;
}
.header {
    height: 70px;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $white;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}
.header .main-menu-container {
    width: 100%;
    padding-inline-start: 6px;
    position: relative;
}
.left-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        border-radius: 6px;
        padding: 6px 6px 0 6px;
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                box-shadow: 0px 4px 10px $secondary;
            }
        }
    }
}
.left-header-wrapper #my-events {
    height: 70px;
    padding-inline-start: 4px;
    margin-inline-start: 10px;
    /*    border-inline-start: 1px solid $disabled-text;*/
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.left-header-wrapper #my-events .my-account-component.dropdown-component {
    top: 70px;
    z-index: 6;
    left: 0;
}
.left-header-wrapper #my-events p {
    color: $secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none !important;
    }
}
.left-header-wrapper #my-events p span {
    // overflow: visible !important;
    padding-inline-end: 15px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    padding: 6px 6px 3px 6px;

    span {
        overflow: hidden !important;
        position: relative;
        //overflow-wrap: anywhere;
        overflow-wrap: break-word;
        top: -1px;
        border-radius: 6px;

        &.rotate-arrow {
            svg {
                transition: 0.3s ease;
                transform: scale(-1);
            }
        }

        &.single-row {
            @media screen and (min-width: 1025px) and (max-width: 1280px) {
                -webkit-line-clamp: 1;
            }
        }

        svg {
            transition: 0.3s ease;
            transform: scale(1);
            margin-right: 6px;
            margin-top: -3px;
        }

        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none !important;
                box-shadow: 0px 4px 10px $secondary;
            }
        }
    }
}
.main-menu-container .actions-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.main-menu-container .main-header-wrapper {
    font-size: 0;

    #my-booths.button-link #account-drop {
        left: 0 !important;
    }

    .back-button,
    .button-link {
        display: inline-block;
        cursor: pointer;
        text-align: center;
        margin-inline-end: 8px;
        line-height: 1;
        position: relative;
        padding: 6px;
        border-radius: 6px;

        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                box-shadow: 0px 4px 10px $secondary;
            }
        }
    }
}
.main-menu-container .main-header-wrapper > div,
.right-header-wrapper > div {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    // margin-inline-end: 35px;
    line-height: 1;
    position: relative;
}
.main-menu-container .main-header-wrapper #chats {
    position: relative;
}
.main-menu-container .main-header-wrapper #chats span {
    position: relative;
}
.main-menu-container .main-header-wrapper #chats div {
    height: 18px;
    width: 18px;
    position: absolute;
    left: 12px;
    bottom: -5px;
    border-radius: 50%;
    color: white;
    background-color: $primary;
    font-weight: 500;
}
.main-menu-container .right-header-wrapper > div:last-of-type {
    margin-inline-end: 31px;
    padding: 4px 6px;
    border-radius: 6px;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 4px 10px $secondary;
        }
    }
}
.main-menu-container .button-link-label,
.right-header-wrapper .button-link-label {
    margin: 0;
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    color: $text-grey;
    padding-top: 6px;
}
.main-menu-container .active .button-link-label,
.right-header-wrapper .active .button-link-label {
    color: $primary;
}
.right-header-wrapper {
    font-size: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    .menu-anncouncements-icon {
        &.gray-icon {
            svg {
                path {
                    fill: $text-grey;
                }
                height: 24px;
            }
        }
        &.purple-icon {
            svg {
                path {
                    fill: $secondary;
                }
                height: 24px;
            }
        }
    }
}
.right-header-wrapper > div:first-child {
    position: relative;
    padding-inline-end: 0;
}
.outside-event-header {
    position: absolute;
    z-index: 99;
    width: 100%;
    &.hide-bottom-menu {
        @media screen and (max-width: 767.9px) {
            .mobile-menu-wrapper {
                display: none;
            }
        }
    }
}
.outside-event-header .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.2s;
}
.remove-platform-menu {
    .platform {
        &.menu-wrapper,
        &.mobile-menu-wrapper {
            display: none;
        }
    }
}
.menu-wrapper .brand-logo {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}
.menu-wrapper .select-language-container {
    margin-inline-start: 4px;
    /*
    color: $white;
    */
    position: relative;
    cursor: pointer;
}
.logo-and-social-and-lang {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .menu-wrapper {
        display: flex;
        margin-right: 28px !important;
    }
}
.right-header-wrapper .select-language-container {
    margin-inline-end: 2px;
    color: $white;
    position: relative;
    cursor: pointer;
    margin: 0 0 0 12px;
    padding: 4px 6px;
    border-radius: 6px;

    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 4px 10px $secondary;
        }
    }
}
.menu-wrapper .select-language-container .languages-list-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    border: 1px solid $disabled-text;
    border-radius: 6px;
    box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
    background: $dark;
    color: $white;
}
.right-header-wrapper .select-language-container .languages-list-container {
    position: absolute;
    top: 45px;
    right: -14.5px;
    width: 119px;
    border: 1px solid $disabled-text;
    border-radius: 7px;
    box-shadow: 0 14px 24px rgba(32, 15, 54, 0.25);
    background: $dark;
    color: $white;
    z-index: 6;
}
.menu-wrapper .select-language-container .languages-list-container ul,
.right-header-wrapper .select-language-container .languages-list-container ul {
    margin: 0;
    list-style: none;
    padding: 0;
    text-transform: uppercase;
}
.menu-wrapper .select-language-container .languages-list-container li {
    cursor: pointer;
    padding: 10px 10px 10px 20px;
}
.right-header-wrapper .select-language-container .languages-list-container li {
    cursor: pointer;
    padding: 10px 10px 10px 30px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
}
.menu-wrapper .select-language-container .languages-list-container li,
.right-header-wrapper .select-language-container .languages-list-container li {
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            background-color: $secondary;
        }
    }
}
.menu-wrapper .select-language-container .languages-list-container li:hover,
.right-header-wrapper .select-language-container .languages-list-container li:hover {
    background: var(--li-background-color);
    &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    &:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
.menu-wrapper .select-language-container .languages-list-container li:hover {
    &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}
.menu-wrapper .select-language-container .languages-list-container li.active,
.right-header-wrapper .select-language-container .languages-list-container li.active {
    background: $primary;
    &:first-of-type {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}
.menu-wrapper .select-language-container .selected-language {
    display: flex;
    align-items: center;
    border-radius: 6px;
    text-transform: uppercase;
    justify-content: space-between;
    padding: 10px 12px;
    /*
    width: 90px;
    */
    font-size: 14px;
}
.right-header-wrapper .select-language-container .selected-language {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    width: 80px;
    color: $text-grey;
}
.right-header-wrapper .selected-language span {
    font-size: 14px;
    font-weight: 600;
    font-family: $fontFamily, sans-serif;
}
.right-header-wrapper .selected-language svg {
    width: 18px;
    height: 20px;
    margin-inline-start: 10px;
}
.menu-wrapper.scrolled {
    .select-language-container {
        color: $dark;
    }

    .sm-share-button svg path {
        fill: $dark;
    }

    .menu-links {
        .homepage-logout-button,
        .login-platform {
            border-color: $dark;
            color: $dark;
        }

        a {
            &.poster {
                background-color: $dark;
                color: $white;
                border-color: $white;
                &:hover {
                    background-color: $white;
                    color: $dark;
                    border-color: $dark;
                }
            }
            &.log-out {
                border-color: $dark;
                color: $dark;
            }
            &.login-event {
                border-color: $dark;
                color: $dark;
            }
        }
    }
}
.gdpr-page {
    .menu-wrapper {
        background: $white;
        .menu-links {
            .homepage-logout-button,
            .login-platform {
                border-color: $dark;
                color: $dark;
            }
        }
    }
}
.outside-event-header.white .menu-wrapper {
    background: $white;
}
.outside-event-header .menu-wrapper.scrolled {
    /*background: linear-gradient(0deg, rgba(0, 125, 187, 0.5), rgba(0, 125, 187, 0.5)), #000000;*/
    background: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    padding-bottom: 15px;
    padding-top: 15px;
}
.outside-event-header .platform.menu-wrapper {
    min-height: 78px;
}
.outside-event-header .platform.menu-wrapper.scrolled {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    background: $white;
    padding-top: 15px;
    padding-bottom: 15px;
}
.outside-event-header .menu-wrapper .brand-logo a {
    position: static;
}
.outside-event-header.white .menu-links a {
    color: $white;
}

.outside-event-header .menu-links,
.links-register-pages .menu-links {
    .homepage-logout-button {
        margin-inline-start: 16px;
        width: 175px;
    }
    //burası main
    a.login-event {
        background-color: $white;
        color: $black-variant;
        padding-left: 15.5px;
        padding-right: 15.5px;
        width: auto;
        /* margin-inline-end: 16px;*/
    }
    a {
        color: $white;
        letter-spacing: 0.0125em;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-inline-start: 16px;
        position: relative;
        padding: 7px 21px;
        width: 175px;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        border-radius: 8px;
        border: 1px solid $white;
        min-height: 48px;
        cursor: pointer;
        &.participant,
        &.login {
            background-color: var(--primary-color);
            border-bottom-right-radius: 0;
            &:hover {
                background-color: $white;
                color: var(--primary-color);
                border-color: var(--primary-color);
            }
        }
        &.exhibitor,
        &.register {
            background-color: var(--secondary-color);
            border-bottom-left-radius: 0;
            &:hover {
                background-color: $white;
                color: var(--secondary-color);
                border-color: var(--secondary-color);
            }
        }
        &.poster {
            background-color: $white;
            border-bottom-left-radius: 0;
            color: $dark;
            &:hover {
                background-color: $dark;
                color: $white;
            }
        }
        &.disabled {
            position: relative;
            border-color: rgba(255, 255, 255, 0.5);
            cursor: initial;
            span {
                opacity: 0.5;
            }
            .info-tooltip {
                display: none;
                position: absolute;
                top: calc(100% + 4px);
            }
            &:hover {
                .info-tooltip {
                    display: block;
                }
            }
        }
    }
}
.links-register-pages {
    padding-top: 27px;
    .menu-links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .homepage-logout-button {
            border-bottom-right-radius: 8px !important;
            border-bottom-left-radius: 0;
            margin-bottom: 27px;
        }

        a {
            border-bottom-right-radius: 8px !important;
            border-bottom-left-radius: 0;
            margin-bottom: 27px;
        }
    }
}
.mobile-menu-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    transition: all 0.2s ease-in-out;
    flex-flow: column;
    display: none;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    overflow: hidden;
    &.opened {
        overflow: initial;
        .menu-links {
            .homepage-logout-button {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 0;
                margin-bottom: 16px;
            }
            a {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 0;
            }
            a.login-event {
                width: 175px;
                margin-inline-end: 0;
                background-color: transparent;
                color: white;
            }
        }
    }
    &.hide {
        display: none;
    }
    .top-links-container {
        justify-content: flex-end;
        display: flex;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding: 0;
        transition: all 0.2s ease-in-out;
        align-items: flex-end;
        flex-flow: column;
    }
    .bottom-links-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .menu-links {
            &.simple-menu {
                display: flex;
                width: 100%;
                justify-content: center;
                a {
                    &:first-of-type {
                        margin-inline-end: 16px;
                    }
                    @media screen and (max-width: 399px) {
                        width: auto;
                        padding-inline-start: 14px;
                        padding-inline-end: 14px;
                    }
                }
            }
        }
    }
    &.platform {
        .bottom-links-container {
            justify-content: flex-end;
        }
    }
    .disabled .info-tooltip {
        top: initial !important;
        bottom: 100%;
        right: 0;
    }
}
.mobile-menu-wrapper .top-links-container .announcement-text {
    width: 100%;
    text-align: center;
}
.mobile-menu-wrapper .top-links-container .announcement-text p {
    margin: 0;
    color: $white-variant;
    font-size: 12px;
}
.mobile-menu-wrapper.opened .top-links-container {
    transition: all 0.2s ease-in-out;
    height: 65px;
}
.mobile-menu-wrapper .top-links-container a {
    text-align: center;
    margin-bottom: 16px;
}
.mobile-menu-wrapper .top-links-container .menu-links {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}
.mobile-menu-wrapper .button-container {
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
.mobile-menu-wrapper.opened .button-container {
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in-out;
}
.mobile-menu-wrapper .button-container .menu-bar {
    height: 2px;
    border-radius: 2px;
    background-color: var(--primary-color);
    width: 100%;
}
.outside-event-header .mobile-menu-wrapper .menu-links a {
    margin-inline-start: 0;
}
.my-events-list-wrapper {
    height: calc(100vh - 100px);
    max-height: 450px;
    padding: 0 !important;
}
.my-events-list {
    padding: 20px 0;
    margin: 0;
}
.my-events-list li {
    cursor: pointer;

    &:last-child {
        @media (hover: hover) {
            &:focus {
                outline: none;
            }
            &.focus-visible {
                outline: none;
                span {
                    outline: none;
                    color: $secondary;
                }
            }
        }
    }

    span {
        padding: 3px;
        font-weight: bold;
        overflow: hidden;
    }
}
.my-events-list li.active {
    cursor: initial;
    color: $primary !important;
    font-weight: bold;
}

.menu-wrapper {
    &.platform {
        .menu-links {
            position: relative;
        }
        .announcement-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            p {
                color: $white-variant;
                margin: 0;
                font-size: 14px;
            }
        }
        &.scrolled {
            .announcement-text {
                p {
                    color: $text-grey;
                }
            }
        }
    }
}
.outside-event-header .menu-links a:hover:after {
    display: none !important;
}
#myaccount {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

#notifications {
    position: relative;
    margin: 0 25px;
    padding: 4px 6px;
    border-radius: 6px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    @media (hover: hover) {
        &:focus {
            outline: none;
        }
        &.focus-visible {
            outline: none;
            box-shadow: 0px 4px 10px $secondary;
        }
    }
}
#notifications .not-read-notifications {
    height: 18px;
    width: 18px;
    position: absolute;
    right: 0;
    bottom: 3px;
    border-radius: 50%;
    color: $white;
    background-color: $primary;
    font-weight: 500;

    @media screen and (max-width: 1024px) {
        height: 14px;
        width: 14px;
    }
}
.top-container {
    &.ch {
        .main-menu-container {
            .main-header-wrapper {
                & > div {
                    flex: initial;
                }
            }
        }
    }
}
// START - Share content on Social Media platforms
.sm-share-button {
    justify-content: flex-start;
    &:active svg {
        fill: white;
    }
    // remove extra width to fit the svg
    .MuiButton-label {
        width: 18px;
    }

    @media (hover: hover) {
        &:focus:is([data-focus-visible-added]) {
            outline: none;
            box-shadow: 0px 4px 10px $secondary;
        }
    }
}
.right-header-wrapper {
    .sm-share-button {
        min-width: 34px !important;
        min-height: 34px;
        left: 6px;
    }
    .sm-share-icon {
        transform: scale(1);
    }

    @media screen and (max-width: 1073px) {
        .sm-share-button {
            left: 4px;
        }
    }
}
.sm-popup-dialog {
    width: 560px;
    height: 196px;

    @media screen and (max-width: 560px) {
        height: auto;
    }
}
.sm-dialog-title {
    padding: 24px 40px 0 28px !important;

    @media screen and (max-width: 560px) {
        padding: 10px 40px 0 28px !important;
        h2 {
            font-size: 16px !important;
        }
    }
}
.sm-popup-close-container {
    position: absolute;
    top: 0;
    right: 0;
}
.sm-popup-close-btn {
    max-height: 24px;
    min-width: 24px !important;
}
.sm-dialog-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 28px 28px 36px 28px !important;
    overflow: hidden !important;
}
.sm-item {
    cursor: pointer;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    svg {
        margin-bottom: 6px;
    }
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: $dark;
}
.sm-item:first-child,
.sm-item:nth-child(2),
.sm-item:nth-child(3),
.sm-item:nth-child(4) {
    margin-right: 28px;

    @media screen and (max-width: 560px) {
        margin-bottom: 24px;
    }
}
:is(.lang-rtl) {
    .sm-item:nth-child(5) {
        margin-right: 28px;

        @media screen and (max-width: 560px) {
            margin-bottom: 24px;
        }
    }
}
// END - Share content on Social Media platforms
@media screen and (max-width: 1426px) {
    .main-menu-container .main-header-wrapper > div {
        flex: 0 1 0;
    }
}
@media all and (max-width: 1365.9px) {
    .platform.menu-wrapper .announcement-text {
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
    }
    .left-header-wrapper #my-events p span {
        /*max-width: 200px;*/
    }
}
@media all and (max-width: 1199.9px) {
    .platform.menu-wrapper .announcement-text {
        max-width: 185px;
    }
    .left-header-wrapper #my-events p span {
        /*max-width: 200px;*/
    }
    .mobile-menu-wrapper {
        display: flex;
    }
}
@media screen and (min-width: 1025px) {
    .main-menu-container .main-header-wrapper {
        display: inline-flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        // padding-inline-end: 30px;
    }
    .main-menu-container .main-header-wrapper > div,
    .right-header-wrapper > div {
        // margin-inline-end: 15px;
    }
    .left-header-wrapper #my-events p {
        margin: 0;
    }
    .left-header-wrapper #my-events p span {
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        text-align: start;
        white-space: normal;
        position: relative;
        padding-inline-end: 30px;
    }
    .left-header-wrapper #my-events p > span {
        padding-right: 30px;
    }
    .left-header-wrapper #my-events span svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
@media screen and (min-width: 1100px) {
    .left-header-wrapper #my-events p > span {
        max-width: 395px;
    }
}
@media screen and (max-width: 1350px) {
    .left-header-wrapper #my-events p .max-width-event-name {
        max-width: 147px;
    }
}
@media screen and (max-width: 1350px) {
    .left-header-wrapper #my-events p .max-width-event-name-de {
        max-width: 142px;
    }
}
@media screen and (min-width: 1300px) {
    .main-menu-container .main-header-wrapper > div {
        // margin-inline-end: 30px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1073px) {
    .right-header-wrapper .select-language-container .selected-language {
        width: auto;
    }
    .right-header-wrapper > div:first-child {
        margin-inline-end: 15px;
    }
    .right-header-wrapper .select-language-container .languages-list-container {
        right: -38px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1199px) {
    .main-menu-container .right-header-wrapper > div:last-of-type {
        margin-inline-end: 15px;
    }
}
@media screen and (max-width: 1024px) {
    .main-menu-container .main-header-wrapper > div {
        flex: initial;
    }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
    .outside-event-header {
        .menu-wrapper {
            padding: 15px 30px;
        }
    }
}
@media all and (max-width: 767.9px) {
    .platform.menu-wrapper .announcement-text {
        display: none;
    }

    .outside-event-header {
        &.hide-bottom-menu:not(.white) {
            .menu-wrapper {
                &.scrolled {
                    position: static;
                    background: none;
                    box-shadow: none;
                    padding-bottom: 15px;
                    padding-top: 30px;
                    .selected-language {
                        color: $white;
                    }
                }
            }
            &.sign-up-tab {
                .selected-language {
                    color: $dark;
                }
                .menu-wrapper {
                    &.scrolled {
                        .selected-language {
                            color: $dark;
                        }
                    }
                }
            }
        }
        &.white {
            .menu-wrapper {
                box-shadow: none;
                padding-top: 15px;
                .brand-logo {
                    a {
                        display: flex;
                    }
                }
            }
            .mobile-menu-wrapper {
                display: flex;
            }
        }
    }
    .outside-event-header .menu-links a.register {
        padding: 8px 20px;
        width: auto;
        margin-inline-start: 16px;
    }
    .platform.mobile-menu-wrapper .menu-links a.second-link {
        margin-right: 24px;
        z-index: -2;
        position: relative;
        opacity: 0;
        transition: opacity 0.2s;
    }
    .platform.mobile-menu-wrapper.opened .menu-links a.second-link {
        z-index: 1;
        opacity: 1;
        transition: opacity 0.2s;
        color: $white;
    }
    .platform.mobile-menu-wrapper.opened .top-links-container {
        height: 0 !important;
    }
    .platform.mobile-menu-wrapper .top-links-container .announcement-text {
        padding-bottom: 30px;
    }
    .platform.mobile-menu-wrapper .top-links-container .menu-links {
        display: none;
    }
    .menu-wrapper .select-language-container {
        margin-inline-end: 0;
    }
}
@media all and (max-width: 465px) {
    .platform {
        &.mobile-menu-wrapper {
            .menu-links {
                a {
                    width: auto;
                }
            }
        }
    }
}
@media all and (max-width: 379px) {
    .platform.mobile-menu-wrapper .menu-links a.second-link {
        display: none !important;
    }
    .platform.mobile-menu-wrapper.opened .top-links-container {
        height: 70px !important;
    }
    .platform.mobile-menu-wrapper .top-links-container .announcement-text {
        padding-bottom: 0;
    }
    .platform.mobile-menu-wrapper .top-links-container .menu-links {
        display: initial;
    }
}

@media (hover: hover) {
    .outside-event-header .menu-links a:hover:after {
        content: '';
        background-color: $primary;
        height: 4px;
        width: 100%;
        left: 0;
        bottom: -14px;
        position: absolute;
    }
    .outside-event-header .menu-links a.register:hover:after {
        display: none;
    }
}

.leave-session-button {
    display: flex !important;
    justify-content: center;
    align-items: center;
    p {
        margin: 0;
        font-family: $fontFamily, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        color: #726f79;
        padding-left: 12px;
    }
}
.login-divider {
    border-left: 1px solid white;
}

.long-event-name {
    overflow-wrap: anywhere;
}

.language-arrow {
    scale: 2;
}
.selected-language.color-white {
    color: white;
}
