// we need to decomment organizerDashboard.scss lines 2 - 11
// I commented them out because they are used in this file and they override the styles of the textfield component
// when we finish implementing the textfield component we will delete them
// -----------------------------------------------------------------------------



// we need to comment ou the .css from APP.scss line 1426
// .error-message, .error-text, .MuiFormHelperText-root.Mui-error {.....}
.MuiFormHelperText-root{
    margin: 4px 0 0 0;
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    color: #777081;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.4px ;
    font-weight: 500;
    padding-inline-start: 12px;
    &.Mui-error{
        color: #D82A2A;
    }
}

.char-count{
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: #777081;
}

.multiline-input{
    position: relative;
    .char-count{
        position: absolute;
        bottom: 24px;
        right: 12px;
    }
}

.MuiInputBase-root .MuiInputAdornment-positionStart{
    margin: 0;
}

.MuiOutlinedInput-adornedEnd {
    padding-right: 12px;
}

.MuiInputBase-input{
    height: auto;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.MuiInputLabel-root{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #777081;
}

.MuiOutlinedInput-input{
    padding: 18px 12px;
}

.MuiInputLabel-outlined{
    transform: translate(12px, 18px) scale(1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
}


.MuiFormControl-marginDense{
    margin: 0;
}

.MuiOutlinedInput-inputMarginDense{
    padding: 14px 12px;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense{
    transform: translate(14px, 14px) scale(1)
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
}

.Mui-focused{
    &.MuiFormLabel-root{
        color: #007DBB;
        &.Mui-error{
            color: #e73131;
        }
    }
    &.MuiFormLabel-colorSecondary{
        color: #208918
    }
}


.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: #2b2b2b;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #007DBB;
}

.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #208918;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #e73131;
}


.MuiOutlinedInput-notchedOutline{
    border-color: #898989;
}


// disabled
.MuiInputLabel-root.Mui-disabled{
    color: #777081;
}

.MuiInputBase-root.Mui-disabled{
    color: #777081;
    .MuiOutlinedInput-notchedOutline{
        border-color: #CACBD0;
    }
}


.MuiTextField-root {
    .MuiInputBase-input {
        &.MuiOutlinedInput-inputMultiline{
            padding: 0;
        }
    }
}

.MuiOutlinedInput-multiline{
    padding: 16px 12px 48px;
}

// keep all text inputs with left align on rtl languages
.lang-rtl .MuiTextField-root {
    direction: ltr;
}


.hidden-input-and-only-display-error-message .MuiInputBase-root{
    display: none !important;
}