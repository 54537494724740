.MuiChip-root {
    height: auto;
    color: #2b2b2b;
    border-radius: 6px;
    background: #F5F5F5;
    border: none;
    padding: 12px 8px;
    &.MuiChip-outlinedSecondary,
    &.MuiChip-outlinedPrimary{
        padding: 11px 7px;;
        // border is controleed in the .Outlined classes below
    }
    &.MuiChip-sizeSmall{
        padding: 8px;
        border: none;
        &.MuiChip-outlinedSecondary,
        &.MuiChip-outlinedPrimary{
            padding: 7px;
            // border is controleed in the .Outlined classes below
        }

    }

    &.MuiChip-outlined{
        background: #F5F5F5;
        background-color: #F5F5F5;
        border: 1px solid #898989;
    }

    .MuiChip-icon,
    .MuiChip-deleteIcon{
        margin: 0;
        font-size: 16px;
        width: 16px;
        height: 16px;
        color: inherit
    }
    .MuiChip-deleteIcon{
        color: #898989;
    }
    .MuiChip-icon{
        margin-inline-end: 8px;
    }
    .MuiChip-deleteIcon{
        margin-inline-start: 8px;
    }

    // disabled
    &.Mui-disabled{
        background: #EFEEF1;
        background-color: #EFEEF1;
        opacity: 1;
        color: #898989;
        border: 1px solid #EFEEF1;
    }

    &.Mui-focusVisible {
        background: #EFE4FF !important;
        border: 2px dashed #007DBB !important;
        padding: 10px 6px;
        color: #2b2b2b !important;
        &.MuiChip-sizeSmall{
            padding: 6px;
        }
        .MuiChip-icon{
            color: #2b2b2b !important;
        }
        .MuiChip-deleteIcon{
            color: #2b2b2b !important;
        }
    }
    &:active{
        box-shadow: none;
    }
}

.MuiChip-colorSecondary{
    color: white;
    background: linear-gradient(164.25deg, #1AAD02 -3.28%, #008729 17.78%, #017938 33.77%, #017B60 70.8%, #018C73 80.08%);
    // I need to add this background-color as similar as the one above
    // so that we can avoid the "flickering"
    background-color: #017938;
    &:hover{
        background: #0F0F0F;
    }
    .MuiChip-deleteIcon{
        color: inherit;
    }
    &.MuiChip-clickable:active{
        background: #2B2B2B;
        background-color: #2B2B2B;
        color: white;
    }
}
.MuiChip-colorPrimary {
    color: white;
    background: linear-gradient(156.39deg,#007DBB 48.1%,#58B0E5 105%);
    // I need to add this background-color as similar as the one above
    // so that we can avoid the "flickering"
    background-color: #007DBB;
    &:hover{
        background: #0F0F0F;
    }
    .MuiChip-deleteIcon{
        color: inherit;
    }
    &.MuiChip-clickable:active{
        background: #2B2B2B;
        background-color: #2B2B2B;
        color: white;
    }
}


.MuiChip-outlinedPrimary{
    color: #2b2b2b;
    .MuiChip-deleteIcon{
        color: #898989;
    }
    &:hover{
        border: 1px solid #007DBB;
        .MuiChip-icon{
            color: #007DBB;
        }
        .MuiChip-deleteIcon{
            color: #007DBB;
        }
    }
    &.MuiChip-clickable:active{
        background: #007DBB;
        background-color: #007DBB;
        color: white;
        .MuiChip-icon{
            color: white;
        }
        .MuiChip-deleteIcon{
            color: white;
        }
    }
}
.MuiChip-deleteIconOutlinedColorPrimary{
    color: #2b2b2b;
}

.MuiChip-outlinedSecondary {
    color: #2b2b2b;
    .MuiChip-deleteIcon{
        color: #898989;
    }
    &:hover{
        border: 1px solid #208918;
        .MuiChip-icon{
            color: #208918;
        }
        .MuiChip-deleteIcon{
            color: #208918;
        }
    }
    &.MuiChip-clickable:active{
        background: #208918;
        background-color: #208918;
        color: white;
        .MuiChip-icon{
            color: white;
        }
        .MuiChip-deleteIcon{
            color: white;
        }
    }
}
.MuiChip-deleteIconOutlinedColorSecondary{
    color: #2b2b2b;
}

.MuiChip-label{
    padding-left: 0;
    padding-right: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
