.MuiButton-disableElevation {
    box-shadow: none !important;
}


.MuiButton-root{
    color: #0F0F0F;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    &:active{
        color: white;
        background: #208918;
    }
}

.MuiIconButton-root{
    color: #2b2b2b;
}

// I used 'not' because I couldn't find other solution to target only
// IconButton class from actual buttons
// TODO: find a better solution
.MuiIconButton-colorSecondary:not(.MuiRadio-root):not(.MuiCheckbox-root) {
    color: #208918;
}

// I used 'not' because I couldn't find other solution to target only
// IconButton class from actual buttons
// TODO: find a better solution
.MuiIconButton-colorPrimary:not(.MuiRadio-root):not(.MuiCheckbox-root) {
    color: #007DBB;
}

.MuiButton-label{
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.MuiButton-startIcon{
    margin-inline-start: 0 !important;
    margin-inline-end: 8px !important;
}
.MuiButton-endIcon{
    margin-inline-start: 8px !important;
    margin-inline-end: 0 !important;
}

// Small BUTTON
.MuiButton-root.MuiButton-sizeSmall{
    padding: 4px 8px;
    &.MuiButton-outlined{
        padding: 3px 7px;
    }
    .MuiButton-startIcon{
        margin-inline-start: 0 !important;
        margin-inline-end: 4px !important;
    }
    .MuiButton-endIcon{
        margin-inline-start: 4px !important;
        margin-inline-end: 0 !important;
    }
}
.MuiButton-iconSizeSmall > *:first-child {
    font-size: 16px;
}

// MEDIUM (normal) button
.MuiButton-root {
    padding: 8px 12px;
    &.MuiButton-outlined{
        padding: 7px 11px;
    }
}

// LARGE BUTTON
.MuiButton-root.MuiButton-sizeLarge{
    padding: 12px;
    &.MuiButton-outlined{
        padding: 11px 11px;
    }
    .MuiButton-label{
        font-weight: 700;
        font-size: 16px;
    }
}

.MuiButton-iconSizeMedium > *:first-child,
.MuiButton-iconSizeLarge > *:first-child{
    font-size: 24px;
}

 .MuiButton-root.Mui-disabled {
     color: #959595;
 }


// text button
.MuiButton-text{
    box-shadow: none;
}

.MuiButton-textPrimary{
    color: #007DBB;
    &:hover{
        color: #2b2b2b;
        background: #F5F5F5;
    }
    &:active{
        color: #007DBB;
        background: #F5F5F5;
    }
}

.MuiButton-textSecondary {
    color: #208918;
    &:hover{
        background: #F5F5F5;
    }
    &:active{
        color: white;
        background: #208918;
    }
}

// outlined
.MuiButton-outlined{
    box-shadow: none;
    &.Mui-disabled{
        border-color: #959595;
    }
}

.MuiButton-outlinedPrimary {
    border-color: #007DBB;
    color: #007DBB;
    &:hover{
        color: #2B2B2B;
        border-color: #2B2B2B;
    }
    &:active{
        color: #2B2B2B;
        background: transparent;
    }
}
.MuiButton-outlinedSecondary {
    border-color: #208918;
    color: #208918;
    &:hover{
        color: #2B2B2B;
        border-color: #2B2B2B;
    }
    &:active{
        color: #2B2B2B;
        background: transparent;
    }
}


// contained
.MuiButton-contained{
    &:hover{
        box-shadow: 0px 14px 24px rgba(32, 15, 54, 0.25);
    }
    &.Mui-disabled{
        background: #F5F5F5 !important;
    }
}
.MuiButton-containedPrimary {
    background: linear-gradient(156.39deg, #007DBB 48.1%, #58B0E5 105%);
    color: white;
    &.Mui-focusVisible {
        background: #EFE4FF;
    }
    &.MuiButton-disableElevation{
        background: #007DBB;
        &.Mui-disabled {
            background: #F5F5F5;
        }
        &:hover{
            background: #1FA9C4;
        }
    }
}

.MuiButton-containedSecondary {
    background: linear-gradient(164.25deg, #1AAD02 -3.28%, #008729 17.78%, #017938 33.77%, #017B60 70.8%, #018C73 80.08%);
    color: white;
    &.Mui-focusVisible {
        background: #EFE4FF;
    }
    &.MuiButton-disableElevation{
        background: #208918;
        &.Mui-disabled {
            background: #F5F5F5;
        }
        &:hover{
            background: #4DA146;
        }
    }
}

// for additional colors, we need to add a class to the button
.error.MuiButton-root{
    background: #D82A2A;
    color: white;
    &:active{
        background: #BF2525;
    }
    &.Mui-disabled{
        color: #959595;
        background: #F5F5F5;
    }
    &.MuiButton-disableElevation {
        &:hover{
            background: #E05555;
        }
    }
}

.error.MuiIconButton-root{
    color: #D82A2A;
    &.Mui-disabled{
        color: #959595;
    }
}

.error-text.MuiButton-root{
    background: white;
    color: #D82A2A;
    .MuiButton-label {
        display: flex;
    }
    &:hover{
        background: #F5F5F5;
    }
    &:active{
        background: white;
    }
    &.Mui-disabled{
        color: #959595;
        background: white;
    }
    svg {
        fill: #D82A2A;
    }
}

.light.MuiButton-root{
    color: white;
    border-color: #fff;
    &:hover{
        color: #DDDBDF;
        background: transparent;
        border-color: #DDDBDF;      
    }
    &:active{
        background: none;
    }
    &.Mui-disabled{
        color: #DDDBDF;
        border-color: #DDDBDF;
    }
}

.only-icon-button{
    padding: 0;
    min-width: auto;
}

// focus on button
.MuiButton-root.Mui-focusVisible{
    background: #EFE4FF !important;
    border: 2px dashed #007DBB !important;
    padding: 6px 10px;
    color: #2b2b2b !important;
    &.MuiButton-sizeLarge{
        padding: 10px;
    }
    &.MuiButton-sizeSmall{
        padding: 2px 8px;
    }
}
