@import 'variables.module';

.single-booth-participant-view {
    #show-links {
        width: calc((100vh - 269px) * 0.15755);
        height: calc((100vh - 269px) * 0.09003);
        top: calc((100vh - 269px) * 0.10209);
        right: calc((100vh - 269px) * -0.095);
        &.scholar {
            top: calc((100vh - 269px) * 0.10209);
            right: calc((100vh - 269px) * -0.56);
        }
        .social-logo-image {
            width: calc((100vh - 269px) * 0.15755);
            height: calc((100vh - 269px) * 0.09003);
        }
    }
    #show-documents {
        width: calc((100vh - 269px) * 0.20718);
        height: calc((100vh - 269px) * 0.44115);
        top: calc((100vh - 269px) * 0.16086);
        left: calc((100vh - 269px) * 0.27797);
    }
    #logo {
        width: calc((100vh - 269px) * 0.19839);
        height: calc((100vh - 269px) * 0.10808);
        top: calc((100vh - 269px) * 0.28855);
        right: calc((100vh - 269px) * 0.02347);
    }
    #show-video {
        width: calc((100vh - 269px) * 0.32828);
        height: calc((100vh - 269px) * 0.18165);
        top: calc((100vh - 269px) * -0.15398);
        left: calc((100vh - 269px) * 0.6184);
        &.scholar {
            top: calc((100vh - 269px) * -0.25398);
            left: calc((100vh - 269px) * 0.4);
        }
    }
    .desk-representative.left {
        width: calc((100vh - 269px) * 0.160771);
        height: calc((100vh - 269px) * 0.22508);
        top: calc((100vh - 269px) * 0.0659163);
        right: calc((100vh - 269px) * 0.27);
    }
    .desk-representative.right {
        width: calc((100vh - 269px) * 0.160771);
        height: calc((100vh - 269px) * 0.22508);
        top: calc((100vh - 269px) * 0.0659163);
        right: calc((100vh - 269px) * 0.075);
    }
    .medalion-representatives {
        right: calc((100vh - 269px) * 0.71);
        top: 0;
        &.scholar {
            bottom: calc((100vh - 269px) * -0.32);
            left: calc((100vh - 269px) * 0.72);
            width: calc((100vh - 269px) * 0.09);
            height: calc((100vh - 269px) * 0.44);
            top: auto;
            right: auto;
        }
    }
    .single-medalion-representative {
        width: calc((100vh - 269px) * 0.09227);
        height: calc((100vh - 269px) * 0.09227);
        margin-bottom: calc((100vh - 269px) * 0.023);
    }
    .booth-pdf-presentation {
        width: calc((100vh - 269px) * 0.76);
        height: calc((100vh - 269px) * 0.599);
        top: calc((100vh - 269px) * -0.067);
        left: calc((100vh - 269px) * -0.215);
        overflow: hidden;

        @media screen and (min-width: 1025px) {
            canvas {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
    .presenting-representative {
        width: calc((100vh - 269px) * 0.23503);
        height: calc((100vh - 269px) * 0.62426);
        top: calc((100vh - 269px) * 0.18);
        right: calc((100vh - 269px) * 0.55);
    }
}

#show-links .slick-slider {
    height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    outline: 0;
}
#show-links .slick-track {
    outline: 0;
}
.slick-slide > div > div {
    outline: 0;
}
#show-links .slick-slider img {
    margin: 0 auto;
    outline: 0;
}
.centerOfPage #logo {
    display: flex;
    align-items: center;
    justify-content: center;
}
.single-booth-participant-view #logo {
    cursor: initial;
}
.single-booth-participant-view #show-video > .image-preview-video {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.single-booth-participant-view #show-video .play-button {
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-size: 15%;
}
#logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.centerOfPage .desk-representative.left img,
.centerOfPage .desk-representative.right img,
.centerOfPage .presenting-representative img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.centerOfPage .desk-representative.left:hover,
.centerOfPage .desk-representative.right:hover {
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
}
@keyframes glowShadow {
    to {
        box-shadow: 0 0 10px 5px $secondaryOpacity70;
    }
}
.hovering-container {
    border-radius: 50%;
    box-shadow: 0 0 4px 2px $secondaryOpacity70;
    animation: glowShadow 1s linear infinite alternate;
    -webkit-transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    display: none;
}
.centerOfPage .desk-representative.left:hover .hovering-container,
.centerOfPage .desk-representative.right:hover .hovering-container {
    display: block;
}
.centerOfPage .clickable-element.medalion-representatives:hover {
    box-shadow: none;
}
.medalion-representatives .image-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border: 0.5px solid #ffffff;
    cursor: pointer;
}
.medalion-representatives > div:last-of-type {
    margin: 0;
}
.medalion-representatives .image-container:hover {
    box-shadow: 0 0 3vh 1px rgba(0, 125, 187, 0.7);
    -webkit-transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.medalion-representatives .image-container.no-hover-effect:hover {
    box-shadow: none;
    cursor: initial;
}
#booth-files-dialog .files-download-title {
    font-family: $fontFamily, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(43, 43, 43, 0.9);
    margin-bottom: 10px;
    padding-top: 10px;
}
.files-list li a {
    color: $secondary;
    font-size: 14px;
}
#booth-files-dialog .cancel-button {
    font-size: 18px;
    text-transform: uppercase;
}
.exhibitor-booth-mobile {
    height: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    &.hide {
        .exhibitor-mobile-header {
            .booth-logo-container {
                img {
                    max-height: 200px;
                    height: auto;
                }
            }
            .exhibitor-company {
                height: 200px;
                line-height: 156px;
            }
        }
        .tabs-container {
            display: none;
        }
    }
    .poster-details-mobile {
        padding: 0 16px;
        flex: 1 1 auto;
        overflow-y: scroll;

        @media screen and (min-width: 550px) and (max-width: 1024px) {
            max-width: 600px;
            margin: 0 auto;
        }

        h1 {
            margin: 0;
            padding-top: 12px;
            font-weight: 600;
            font-size: 20px;
        }
        .section {
            padding-top: 12px;
            p {
                margin: 0;
                font-size: 16px;
            }
            .section-subtitle {
                color: $primary;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                padding-bottom: 8px;
            }
            &:last-of-type {
                padding-bottom: 12px;
            }
        }
    }
    .exhibitor-representatives {
        flex: 1 1 auto;
        overflow-y: scroll;
    }
    .exhibitor-assets {
        flex: 1 1 auto;
        overflow-y: scroll;
    }
}

@media all and (max-height: 700px) {
    .exhibitors-slider .featured-exhibitors-container.active img {
        padding-bottom: 5px;
    }
    .exhibitors-slider .slick-dots {
        bottom: -20px;
    }
    .exhibitors-slider .slick-dots li button:before {
        font-size: 6px;
    }
    .exhibitors-slider .slick-arrow {
        height: 30px !important;
    }
    .exhibitors-slider .slick-arrow svg {
        height: 30px;
        width: auto;
    }
}

@media screen and (max-width: 1024px) {
    .showfloor-page {
        display: flex;
        flex-flow: column;
        background-color: #f5f5f5;
    }
    .showfloor-page .booth {
        width: 100%;
    }
    .showfloor-page .tabs-container {
        background-color: #ffffff;
        min-height: 48px;
        height: 48px;
        display: flex;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
    .showfloor-page .tab {
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $grey;
    }
    .showfloor-page .tab.active {
        font-weight: 600;
        font-size: 14px;
        color: $secondary;
        border-bottom: 2px solid $secondary;
    }
    .exhibitors-list-dialog {
        .search-exhibitor-container {
            &.mobile {
                display: none;
                position: relative;

                #standard-search {
                    height: 48px;
                    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.2);
                    padding-inline-end: 40px;

                    // hide x
                    &[type='search']::-ms-clear,
                    &[type='search']::-ms-reveal {
                        display: none;
                        width: 0;
                        height: 0;
                    }
                    &[type='search']::-webkit-search-decoration,
                    &[type='search']::-webkit-search-cancel-button,
                    &[type='search']::-webkit-search-results-button,
                    &[type='search']::-webkit-search-results-decoration {
                        display: none;
                    }
                }
                svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    fill: $secondary;
                }
            }
        }
    }
    .exhibitors-overlay-mobile h4,
    .exhibitors-list-dialog h4 {
        background-color: $primary;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        padding: 0 16px;
        cursor: pointer;
        height: 48px;

        &.hide {
            & > div {
                &:first-of-type {
                    svg {
                        margin-inline-end: 0;

                        &.arrow-up {
                            margin-inline-start: 0;
                            transform-origin: 50% 50%;
                        }
                    }
                }
            }
        }

        p {
            display: flex;
            flex-direction: column;
            margin: 0;
            flex: 1;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
                padding-inline-end: 15px;

                &:first-of-type {
                    font-weight: 600;
                    font-size: 16px;
                }
                &:last-of-type {
                    font-weight: 500;
                    font-size: 14px;
                }

                &.poster-title {
                    -webkit-line-clamp: 2;
                    word-break: break-word;
                    font-size: 16px;
                    font-weight: 600;

                    &.showfloor {
                        -webkit-line-clamp: 1;
                        word-break: break-all;
                    }
                }
            }
        }

        & > div {
            display: flex;

            &:first-of-type {
                flex: 1;
                justify-content: space-between;
                align-items: center;

                & > svg {
                    margin-inline-end: 20px;

                    &.arrow-up {
                        transform: rotate(180deg);
                        transform-origin: 0 50%;
                        margin-inline-start: 20px;
                        margin-inline-end: 0;
                    }
                }
            }

            .search-exhibitors {
                display: flex;
                padding: 0 5px;
            }
        }
    }
    .exhibitors-list-dialog ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .exhibitors-list-dialog ul li {
        display: flex;
        align-items: center;
        padding: 10px 8px;
        font-size: 16px;
        line-height: 20px;
        color: $dark;
        font-weight: 600;
        border-bottom: 1px solid #ece9e9;
        cursor: pointer;
    }
    .exhibitors-list-dialog ul li.active {
        color: $primary;
    }
    .exhibitors-list-dialog ul li .logo-container {
        width: 40px;
        height: 40px;
        margin-inline-end: 12px;
    }
    .exhibitors-list-dialog ul li .logo-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .exhibitor-mobile-header {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        background: #fff;

        &.scholar {
            background: #f5f5f5;
            display: block;
            #pdfPresentation {
                padding: 12px 16px;
                width: 100%;
                display: flex;
                justify-content: center;
                .open-file {
                    background: linear-gradient(156.39deg, $secondary 48.1%, $secondaryDarken 105%);
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 6px;
                    width: 100%;
                    text-align: center;
                    padding: 10px 0;

                    @media screen and (min-width: 550px) and (max-width: 1024px) {
                        max-width: 328px;
                    }

                    span {
                        font-weight: 600;
                        font-size: 14px;
                        color: #ffffff;
                        text-transform: uppercase;
                        font-family: $fontFamily, sans-serif;
                    }
                }
            }
            button {
                &#pdfPresentation {
                    border: 0;
                    &:disabled {
                        .open-file {
                            background: $grey;
                        }
                    }
                }
            }
        }
    }
    .exhibitor-mobile-header .booth-logo-container {
        font-size: 0;
        padding: 8px 16px;
    }
    .exhibitor-mobile-header .exhibitor-company {
        padding: 24px;
        font-size: 24px;
        font-weight: 600;
    }
    .exhibitor-mobile-header .booth-logo-container img {
        max-width: 100%;
        height: 80px;
        width: auto;
    }
    ul.exhibitor-representatives,
    ul.exhibitor-assets {
        margin: 0;
        list-style-type: none;
        padding: 10px 0 0;
    }
    .exhibitor-representatives {
        li {
            background-color: #ffffff;
        }
    }
    .exhibitor-representatives .user-container,
    ul.exhibitor-assets li {
        border-bottom: 1px solid rgba(137, 137, 137, 0.5);
        padding: 10px;
    }
    .exhibitor-representatives .user-details {
        padding: 0;
        border: 0;
    }
    .exhibitor-representatives .user-details .user-name p:last-child {
        margin-bottom: 0;
        &.no-title {
            text-transform: uppercase;
        }
    }
    .exhibitor-representatives .user-details > div:last-of-type {
        min-height: initial;
    }
    .exhibitor-representatives .participant-button.open-chat {
        top: 2px;
    }
    ul.exhibitor-assets li a {
        display: flex;
        justify-content: space-between;
        color: $secondary;
        font-weight: 600;
    }
    ul.exhibitor-assets li a > span {
        max-width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
    ul.exhibitor-assets li a .link {
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        display: inline-block;
    }
    ul.exhibitor-assets li .label {
        color: $dark;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding-inline-end: 5px;
    }
    .exhibitors-list-dialog .MuiDialog-container {
        outline: 0;
        position: fixed;
        top: 56px;
        width: 100%;
    }
    .exhibitors-list-dialog .MuiBackdrop-root {
        top: 56px;
    }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
    .showfloor-page .tabs-container {
        justify-content: center;
    }
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
    ul.exhibitor-representatives {
        width: 600px;
        margin: 0 auto;
    }
}
