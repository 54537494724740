// font family
$fontFamily: 'Montserrat';

// USE ONLY HASH COLORS
$primary: #208918;
$primary-variant: #4da146;
$primary-dark: darken(#208918, 10%);
$primary-variant-light: #d9c8f3;
$primaryColorBranding: rgba(32, 137, 24, 0.99);
$secondary: #007DBB;
$secondary-background: #efe4ff;
$secondaryColorBranding: rgba(0, 125, 187, 0.99);
$secondaryDarken: #58B0E5; // for gradients (on white labels should be secondary color)
$secondary-banner1: #007DBB; // for gradients (on white labels should be secondary color)
$secondary-banner2: #007DBB; // for gradients (on white labels should be secondary color)
$secondary-rooms1: rgba(0, 125, 187, 0.7); // for gradients (on white labels should be secondary color)
$secondary-rooms2_56: rgba(0, 125, 187, 0.56); // secondary color with opacity
$secondary-rooms2_80: rgba(0, 125, 187, 0.8); // secondary color with opacity
$secondary-light: lighten(#007DBB, 45%);
$secondary-hover: #007DBB59; // the last 2 digits represents the alpha parameter (opacity/transparency) - secondary color
$secondaryOpacity70: rgba(0, 125, 187, 0.7); // secondary color
$avatarBckg1: #007DBB; // for gradients (on white labels should be secondary color)
$avatarBckg2: #35cf76; // for gradients (on white labels should be primary color)
$secondaryLight10: rgba(0, 125, 187, 0.1); // secondary color
$showfloorActive1: rgba(0, 125, 187, 0.9); // secondary color with opacity
$showfloorActive2: rgba(0, 125, 187, 0.9); // for gradients (on white labels should be secondary color)
$backGroundOverlay: rgba(0, 125, 187, 0.5); // secondary color with opacity
$dark: #2b2b2b;
$grey: #898989;
$grey-dark: #777081;
$light-grey-v2: #dddbdf;
$grey-variant: #959595;
$text-grey: #726f79;
$light-grey: #efeef1;
$light-grey-variant: #d8d8d8;
$disabled-text: #cacbd0;
$error: #e73131;
$error-light: #eb5757;
$red: #d82a2a;
$black: #000;
$black-variant: #0f0f0f;
$warning: #f2994a;
$white: #ffffff;
$white-variant: #f5f5f5;
$facebook: #4267b2;
$linkedin: #2867b2;
$twitter: #1da1f2;
$online-for-black-bg: #bed8bc;
$doorColorText: #1d1d1b;
$lobbyDoorTextColor: rgba(29, 29, 27, 0.99); // $doorColorText with opacity
$muiDisabled: #b9b9b9;
$hover-wall-action-primary: rgba(32, 137, 24, 0.1);
$gradientMenu1: #92d142; // for white label will be primary color
$gradientMenu2: #34af20; // for white label will be primary color
$gradientMenu3: #09955a; // for white label will be primary color
$matchingBanner1: #1f8e1b; // for white label will be primary color
$matchingBanner2: #16bd31; // for white label will be primary color
$matchingBanner3: #52d268; // for white label will be primary color
$matchingBanner4: #3abe55; // for white label will be primary color
$matchingBanner5: #16bb3a; // for white label will be primary color

// email for custom renders
$emailCustomRender: 'design@unstoppabl.ai';

// social links
$facebookLink: 'myonvent';
$linkedInLink: 'company/skandinavisk-online-karrieremesse/';
$twitterLink: 'myonvent';

// validations for email
$emailEndsWith1: '@myonvent.com';
$emailEndsWith2: '@onvent.online';

// export variables
:export {
    fontFamily: $fontFamily;
    primary: $primary;
    primaryVariant: $primary-variant;
    primaryColorBranding: $primaryColorBranding;
    primaryDark: $primary-dark;
    secondary: $secondary;
    secondaryColorBranding: $secondaryColorBranding;
    secondaryDarken: $secondaryDarken;
    secondaryHover: $secondary-hover;
    secondary-background: $secondary-background;
    secondaryBanner2: $secondary-banner2;
    dark: $dark;
    online-for-black-bg: $online-for-black-bg;
    muiDisabled: $muiDisabled;
    grey: $text-grey;
    lightGrey: $disabled-text;
    darkWhite: $light-grey;
    disabledText: $disabled-text;
    error: $error;
    errorLight: $error-light;
    warning: $warning;
    white: $white;
    whiteVariant: $white-variant;
    greyVariant: $grey;
    greyDark: $grey-dark;
    black: $black;
    facebook: $facebook;
    linkedin: $linkedin;
    twitter: $twitter;
    doorColor: $doorColorText;
    redCancel: $red;
    secondaryLight: $secondary-light;
    gradientMenu1: $gradientMenu1;
    gradientMenu2: $gradientMenu2;
    gradientMenu3: $gradientMenu3;
    secondaryOpacity70: $secondaryOpacity70;
    lobbyDoorTextColor: $lobbyDoorTextColor;
    emailCustomRender: $emailCustomRender;
    facebookLink: #{$facebookLink};
    linkedInLink: #{$linkedInLink};
    twitterLink: #{$twitterLink};
    emailEndsWith1: $emailEndsWith1;
    emailEndsWith2: $emailEndsWith2;
    blackVariant: $black-variant;
}
